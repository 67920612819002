import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b23/img1.jpg';
import img2 from '../../Assets/b23/img2.jpg';
import img3 from '../../Assets/b23/img3.jpg';
import img4 from '../../Assets/b23/img4.jpg';
import img5 from '../../Assets/b23/img5.jpg';
import img6 from '../../Assets/b23/img6.jpg';
import img7 from '../../Assets/b23/img7.jpg';
import img8 from '../../Assets/b23/img8.jpg';
import img9 from '../../Assets/b23/img9.jpg';


function Blog23(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Baking Bonds: Mixing Team Spirit and Creativity!</h1>
            <h6>May 23, 2023 at 10:43 AM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="6" aria-label="Slide 7"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="7" aria-label="Slide 8"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="8" aria-label="Slide 9"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img5} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img6} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img7} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img8} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img9} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Whisking up some team spirit! 🥣</p>
            <p>Our company's team building activity got everyone's creative juices flowing as we took on the challenge of DIY baking. Who knew that measuring ingredients and baking them ourselves could be this much fun?  🍰🧁</p>
            <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/teambuilding' target='_blank' className='blog-link'>
              #TeamBuilding </a>
              <a href='https://www.facebook.com/hashtag/worklifebalance' target='_blank' className='blog-link'>
              #WorkLifeBalance </a>
              <a href='https://www.facebook.com/hashtag/bakingisfun' target='_blank' className='blog-link'>
              #BakingIsFun </a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog23;