import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b49/heroshot.jpg"

function Blog49() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>HRConnect: Bridging Your HR Needs</h1>
                    <h6>July 4, 2024 at 4:07 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Bridge your HR needs with HRConnect today!</p>
                    <p>HRConnect is a comprehensive system featuring Payroll Management, Attendance & Leave Monitoring, Cash Advance & Loan Management, and Documented Performance Feedback.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/hrconnect' target='_blank' className='blog-link'>
                        #HRConnect </a>
                        <a href='https://www.facebook.com/hashtag/bridginghrneeds' target='_blank' className='blog-link'>
                        #BridgingHRNeeds </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog49
