import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b30/cover page-min.jpg';
import img2 from '../../Assets/b30/feature1-min.jpg';
import img3 from '../../Assets/b30/feature2-min.jpg';

function Blog30(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Our Progess, Your Success!</h1>
            <h6>July 28, 2023 at 9:57 AM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>At the heart of our journey lies our unwavering commitment to delivering the best systems and services.
                We have strived to stay at the forefront of technology, continuously innovating to offer you state-of-the-art
                software solutions and reliable hardware that propel your business forward. 🌟🎉</p>
            <p><a href='https://www.facebook.com/hashtag/mytislit' target='_blank' className='blog-link'>
              #MYTisLit </a>
              <a href='https://www.facebook.com/hashtag/myt11years' target='_blank' className='blog-link'>
              #MYT11Years </a>
              <a href='https://www.facebook.com/hashtag/mytevolve' target='_blank' className='blog-link'>
              #MYTEvolve </a>
              <a href='https://www.facebook.com/hashtag/innovativesolutions' target='_blank' className='blog-link'>
              #InnovativeSolutions </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog30;