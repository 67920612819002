import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b6/img1.jpg';
import img2 from '../../Assets/b6/img2.jpg';

function Blog6(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Accounting and Tax 101: Short Course for Small Business Owners</h1>
            <h6>August 10, 2022 at 04:00 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>On the workings of accounting and tax 📊🧾</p>
            <p>Any business has a financial side to keep them up and 
            running--and MYT Solutions is not an exception. Not only do we aim
            to provide better software solutions, but we also care about our
            company's financial position.</p>
            <p>Last July 27, a workshop presented by Arvin Suralta, CPA, MBA and
            Jann del Mar-Seguia, CPA on Accounting and the Basics of Tax in the
            Philippines. MYT's Management Interns were taught of the basics of
            accounting and the fundamentals of taxation in the Philippine
            setting.</p>
            <p><a href='https://www.facebook.com/hashtag/accountingandtax101' target='_blank' className='blog-link'>
              #AccountingandTax101 </a>
              <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
              #ContinuousLearning</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog6;