import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b1/img1.jpg';

function Blog1(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>MYT SoftDev Solutions: Innovative. Intuitive. Powerful.</h1>
            <h6>July 30, 2021 at 03:04 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 text-center'>
            <img src={img1} className="blog-img"/>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Innovative. Intuitive. Powerful.</p>
            <p>MYT Softdev Solutions is a global community of developers, designers,
              and analysts with the vision to create solutions to showcase the
              competitive edge of our clients for better productivity, profitability
              and sustainability. Here at MYT Softdev Solutions, we value our clients’
              dreams and aspirations by helping them through the solutions we provide
              that allows our clients to focus on their business’ core vision and grow
              their business with efficiency and effectiveness.</p>
            <p>Powered with the latest and powerful technologies, the company offers
              top quality services. The company provides user-friendly and responsive
              web-based applications and useful websites customized to assure the best
              results for our clients’ businesses. We continuously help industries with
              solutions that cater to their needs such as virtual streaming platforms,
              chat support systems, digital animations, and more. Along with these, we
              build well-designed and optimized mobile applications suited for Android
              and Apple users and offer high quality digital illustrations and animations.
              The company has clients ranging from different types of industries - marketing,
              manufacturing, logistics, healthcare, retail, and more. Industries of different
              kinds and of serving different purposes have a home with us.</p>
            <p>With a growing community of people driven with passion, the company
              continues to value bringing smarter solutions that people love.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog1;