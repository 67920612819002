import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b51/heroshot.jpg"

function Blog51() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Medease OBGYN: Smart Care for Every Stage of Life</h1>
                    <h6>July 11, 2024 at 2:40 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Transform your OB-GYN practice with MedEase OB-GYN, streamlining e-prescribing and medication management, prenatal and obstetric care recording, and comprehensive assessment histories — supporting women through their entire journey.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/medease' target='_blank' className='blog-link'>
                        #MedEase </a>
                        <a href='https://www.facebook.com/hashtag/obgyn' target='_blank' className='blog-link'>
                        #OBGYN </a>
                        <a href='https://www.facebook.com/hashtag/smartcare' target='_blank' className='blog-link'>
                        #SmartCare </a>
                        <a href='https://www.facebook.com/hashtag/godigital' target='_blank' className='blog-link'>
                        #GoDigital </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog51
