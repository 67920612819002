import React from 'react';
import "./AboutUs.css"
import old_logo from '../../Assets/old_logo.png';
import new_logo from '../../Assets/myt logo.png';
import group_pic from '../../Assets/gp1.png';
import group_pic2 from '../../Assets/gp2.png';
import group_pic3 from '../../Assets/gp3.png';
import group_pic4 from '../../Assets/gp4.png';
import group_pic5 from '../../Assets/gp5.png';
import group_pic6 from '../../Assets/gp6.png';
import group_pic7 from '../../Assets/gp7.png';
import group_pic8 from '../../Assets/gp8.png';
import group_pic9 from '../../Assets/gp9.png';
import group_pic10 from '../../Assets/gp10.png';
import group_pic11 from '../../Assets/gp11.png';
import group_pic12 from '../../Assets/gp12.png';
import group_pic13 from '../../Assets/gp13.png';
import group_pic14 from '../../Assets/gp14.png';
import accreditations from '../../Assets/accreditations.png';
import modulezero from '../../Assets/Module Zero.png';
import foundation from '../../Assets/MYT Foundation.JPG';

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }

 const employees = importAll(require.context('../../Assets/Employees', false, /\.(png|jpe?g|svg)$/));

function AboutUs() {
  return (
    <>
      <div className='container-fluid my-5 py-3 overflow'>
        <div className='row justify-content-center'>
          <div className='col about-text text-center'>
            <h1>About MYT</h1>
            <p>Here at MYT SoftDev Solutions we value you and offer&nbsp;
              <div class="d-none d-md-block"></div>
              you quality service. Ready to serve you and your&nbsp;
              <div class="d-none d-md-block"></div>
              business needs.
            </p>
          </div>
        </div>
        <div className='row justify-content-center gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 about-text text-center'>
            <div className='d-flex logo-box mb-2'>
              <img className='old-logo mx-auto' src={old_logo}/>
            </div>
            <h2>2012 - 2021</h2>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 about-text text-center'>
            <div className='d-flex logo-box mb-2'>
              <img className='new-logo mx-auto' src={new_logo}/>
            </div>
            <h2>2022 - present</h2>
          </div>
        </div>
      </div>
      <div className='container-fluid my-5 py-1'>
        <div className='row justify-content-center'>
          <div className='col-11 col-md-9 about-text text-start text-md-center'>
            <p>MYT SoftDev Solutions Inc. is a 100% Filipino-owned private software 
            development company established in 2012. MYT SoftDev Solutions Inc. aims to 
            engage in all aspects of the business of computer software and applications 
            development with professional customer care and reliable end-to-end project 
            development. Moreover, MYT aspire to create a competitive edge for your 
            business for better productivity, profitability, and sustainability.</p>
          </div>
        </div>
      </div>

       <div className='row'>
          <div className='col about-text text-center mb-4'>
            <h2>KEY PRIORITY AREAS</h2>
            <p>Customer Centricity, Sustainable Profitable Growth, Employee Empowerment</p>
          </div>
        </div>

      <div className='accreditations'>
        {/* <div className='row'> */}
          <div className='col about-text text-center mb-4'>
            <h2>CERTIFICATIONS</h2>
          </div>
        {/* </div> */}
        <img src={accreditations} className='logos'></img>
      </div>

     <div className='container-fluid my-2 py-5'>
        <div className='row justify-content-center'>
          <div className='col-11 col-md-9 about-text text-start text-md-center'>
            <p> As a testament to our unwavering commitment to excellence, we have successfully 
            obtained accreditation from National Privacy Commission, Bureau of Internal Revenue,
            and PhilGeps. These prestigious certifications validate the data privacy and conform
            requirements of our infrastructure, confirming our dedication to maintaining the highest
            standards of security and reliability in all aspects of our operations.</p>
          </div>
        </div>
      </div>
     
      {/* <div className='row'>
          <div className='col about-text text-center mb-4'>
            <h2>OUR STORIES</h2>
          </div>
          <div className='row justify-content-center'>
            <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 about-text text-center'>
              <div className='d-flex logo-box mb-2'>
                <img className='foundation' src={foundation}/>
              </div>
              <h2>MYT FOUNDATION</h2>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 about-text text-center'>
              <div className='d-flex logo-box mb-2'>
                <img className='mz' src={modulezero}/>
              </div>
              <h2>MODULE ZERO</h2>
            </div>
        </div>
      </div> */}

      <div className='container mt-3 pb-5'>
        <div className='row'>
          <div className='col about-text text-center mt-5 mb-4'>
            <h2>Meet the amazing people behind MYT!</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col carouselContainer mx-auto'>
            <div id="groupPictures" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="2" aria-label="Slide 3"></button>
              {/* <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="3" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="4" aria-label="Slide 5"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="5" aria-label="Slide 6"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="6" aria-label="Slide 7"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="7" aria-label="Slide 8"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="8" aria-label="Slide 9"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="9" aria-label="Slide 10"></button>
              <button type="button" data-bs-target="#groupPictures" data-bs-slide-to="10" aria-label="Slide 11"></button> */}
            </div>
              <div className="carousel-inner">
                {/* <div className="carousel-item active">
                  <img src={group_pic} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic2} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic3} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic4} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic5} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic6} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic7} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic8} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic9} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic10} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={group_pic11} className="d-block w-100" alt="..."/>
                </div> */}
                <div className="carousel-item active">
                  <img src={group_pic12} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item active">
                  <img src={group_pic13} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item active">
                  <img src={group_pic14} className="d-block w-100" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#groupPictures" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#groupPictures" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='container my-5'>
        <div className='row mb-3 gy-3 px-3 px-md-0'>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['2.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['3.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['4.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['5.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['6.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['7.png']}/>
            </div>
          </div>
        </div>

        <div className='row mb-3 gy-3 px-3 px-md-0'>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['8.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['9.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['10.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['11.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['12.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['13.png']}/>
            </div>
          </div>
        </div>

        <div className='row gy-3 px-3 px-md-0'>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['14.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['15.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['16.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['17.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['18.png']}/>
            </div>
          </div>
          <div className='col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2'>
            <div className='employee-box'>
              <img src={employees['19.png']}/>
            </div>
          </div>
        </div>
      </div> */}

      <div className='container-fluid my-5 py-5'>
        <div className='row mb-4'>
          <div className='col about-text text-center'>
            <h1>Ready to join MYT?</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col text-center mt-3'>
            <div className='d-flex flex-row justify-content-center'>
            <a href='https://www.module-zero.com/' target='_blank' style={{color:'inherit'}}>
              <button type='button' className='btn btn-primary btn-careers'>Apply Now</button>
            </a>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;