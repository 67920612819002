import React from 'react';
import "./BlogPost.css";

import heroshot from "../../Assets/b36/heroshot.jpg"
import img1 from "../../Assets/b36/img1.jpg"
import img2 from "../../Assets/b36/img2.jpg"
import img3 from "../../Assets/b36/img3.jpg"

function Blog36() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Internship Fair</h1>
                    <h6>December 13, 2023 at 12:00 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={heroshot} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img1} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img2} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img3} className="blog-img" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Thanks to the University of Cebu for inviting us to their Internship Fair and for fostering a platform for growth and learning! 🎓✨ It’s always inspiring to connect with students who are eager to delve into the Tech Industry.</p>
                    <p><a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='blog-link'>
                        #ModuleZero </a>
                        <a href='https://www.facebook.com/hashtag/internship' target='_blank' className='blog-link'>
                            #Internship </a>
                        <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
                            #ConstinuousLearning </a>
                        <a href='https://www.facebook.com/hashtag/careergrowth' target='_blank' className='blog-link'>
                            #CareerGrowth </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog36
