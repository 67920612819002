import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b27/upc1.jpg';
import img2 from '../../Assets/b27/upc2.jpg';
import img3 from '../../Assets/b27/upc3.jpg';
import img4 from '../../Assets/b27/upc4.jpg';
import img5 from '../../Assets/b27/upc5.jpg';

function Blog27(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Breaking New Grounds: Uniting Forces for Innovation!🚀</h1>
            <h6>June 16, 2023 at 11:33 AM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img5} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>We are happy and excited to announce our exciting academic partnership with the University of the Philippines Cebu. Together, we're revolutionizing the way industry and academia collaborate to drive progress, inspire breakthroughs, and shape a brighter future. 🎓</p>
            <p>MYT represented our Module Zero Internship Director Christine Caballes, Technical Project Manager Deson Maquilang, together with our executives Darmae Tan, Kristin Zalameda and Richard Lee discussed with the faculty of the University of the Philippines Cebu Department of Computer Science on an academic internship ensuring a internship and research grounded collaboration between the parties. 🤝</p>
            <p><a href='https://www.facebook.com/hashtag/modulezerointernship' target='_blank' className='blog-link'>
              #ModuleZeroInternship </a>
              <a href='https://www.facebook.com/hashtag/mytxupc' target='_blank' className='blog-link'>
              #MYTxUPC </a>
              <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
              #ContinuousLearning </a>
              <a href='https://www.facebook.com/hashtag/breakingnewgrounds' target='_blank' className='blog-link'>
              #BreakingNewGrounds </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog27;