import React from 'react';
import "./BlogPost.css";
import uphill1 from "../../Assets/b32/uphill-1.jpg";
import uphill2 from "../../Assets/b32/uphill-2.jpg";
import uphill3 from "../../Assets/b32/uphill-3.jpg";

function Blog32(){
    return (
      <>
        <div className='container-fluid my-5'>
          <div className='row mb-5'>
            <div className='col blog-text text-center'>
              <h1>Epic Climb: Uphill Challenge 2023, Rain or Shine</h1>
              <h6>September 13, 2023 at 11:29 AM</h6>
            </div>
          </div>
  
          <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
              <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={uphill1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={uphill2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={uphill3} className="blog-img" alt="..."/>
                </div>
              </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
              <p>Challenge accepted! 🏃‍♂️🏃‍♀️</p>
              <p>We were thrilled to take on the UPHill Challenge 2023, rain or shine! 🌦️ 
                Together, we hit the trails, embraced the uphill battles, and showed that techies can conquer hills too!</p>
              <p>Congratulations to Coach John, our HIIT Coach, for winning 3rd place in the 12km Run. 🥳</p>
               <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                #MYTCulture </a>
                <a href='https://www.facebook.com/hashtag/teambuilding' target='_blank' className='blog-link'>
                #TeamBuilding </a>
                <a href='https://www.facebook.com/hashtag/fitness' target='_blank' className='blog-link'>
                #Fitness </a>
                <a href='https://www.facebook.com/hashtag/uphillchallenge2023' target='_blank' className='blog-link'>
                #UPHillChallenge2023 </a>
                <a href='https://www.facebook.com/hashtag/worklifebalance' target='_blank' className='blog-link'>
                #WorkLifeBalance </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default Blog32;