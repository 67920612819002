import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b22/img1.jpg';
import img2 from '../../Assets/b22/img2.jpg';
import img3 from '../../Assets/b22/img3.jpg';
import img4 from '../../Assets/b22/img4.jpg';
import img5 from '../../Assets/b22/img5.jpg';
import img6 from '../../Assets/b22/img6.jpg';
import img7 from '../../Assets/b22/img7.jpg';


function Blog22(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Summer in MYT</h1>
            <h6>May 4, 2023 at 11:31 AM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="6" aria-label="Slide 7"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img5} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img6} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img7} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>🌞☀️ Soaking up the sun and good vibes in MYT! 🌴🏖️ </p>
            <p>Our company summer outing has been a blast as we embarked on an incredible journey, exploring the stunning destinations of Dalaguete, Boljoon, Moalboal, and Barili. From thrilling activities to breathtaking landscapes, we're creating unforgettable moments as a team that will last a lifetime. 🌊🎉</p>
            <p><a href='https://www.facebook.com/hashtag/summerinmyt' target='_blank' className='blog-link'>
              #SummerInMYT </a>
              <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/mytislit' target='_blank' className='blog-link'>
              #MYTisLit </a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog22;