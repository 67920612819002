import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b52/heroshot.jpg"

function Blog52() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Medease DOC: Smart Solutions for Smarter Healthcare</h1>
                    <h6>July 13, 2024 at 9:23 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Revolutionize your medical practice with MedEase-Doc, simplifying medicine and vaccine dispensing, managing e-prescriptions and laboratory requests, streamlining patient assessments, and utilizing comprehensive form templates to deliver precise, efficient care.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/medease' target='_blank' className='blog-link'>
                        #MedEase </a>
                        <a href='https://www.facebook.com/hashtag/internalmedicine' target='_blank' className='blog-link'>
                        #InternalMedicine </a>
                        <a href='https://www.facebook.com/hashtag/familymedicine' target='_blank' className='blog-link'>
                        #FamilyMedicine </a>
                        <a href='https://www.facebook.com/hashtag/smarthealthcare' target='_blank' className='blog-link'>
                        #SmartHealthcare </a>
                        <a href='https://www.facebook.com/hashtag/godigital' target='_blank' className='blog-link'>
                        #GoDigital </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog52
