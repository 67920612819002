import React from 'react'
import "./BlogPost.css";

import woman1 from "../../Assets/b42/women1.jpg";
import woman2 from '../../Assets/b42/women2.jpg';
import woman3 from '../../Assets/b42/women3.jpg';
import woman4 from '../../Assets/b42/women4.jpg';

function Blog42() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Happy International Women's Day! 💜 </h1>
                <h6>March 8, 2024 at 4:06 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>    
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={woman1} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={woman2} className="blog-img" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={woman3} className="blog-img" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={woman4} className="blog-img" alt="..."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Today, we honor and celebrate every woman who is achieving their goals, overcoming challenges,
                        and serving as a role model, especially in the tech industry. ✨</p>
                    <p>As we celebrate Women's Month, let's reflect on the importance of not just celebrating women but also advocating for equality
                        and fairness between men and women. At MYT, we proudly provide equal opportunities based on character and potential,
                        regardless of gender. Our 50-50 gender distribution is a testament to this commitment. </p>
                    <p>To you out there, remember that you have a place in the tech industry. Keep pushing boundaries, breaking stereotypes, and inspiring others.
                        The future of tech is brighter with you in it. 🫵</p>
                    <p>Now, let's listen to the experiences of the women of MYT. </p>
                    <p><a href='https://www.facebook.com/hashtag/womenintech' target='_blank' className='blog-link'>
                        #WomenInTech </a>
                        <a href='https://www.facebook.com/hashtag/internationalwomensday' target='_blank' className='blog-link'>
                        #InternationalWomensDay </a>
                        <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                        #MYTCulture </a>
                        <a href='https://www.facebook.com/hashtag/equalityandfairness' target='_blank' className='blog-link'>
                        #EqualityAndFairness </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog42
