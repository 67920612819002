import React from 'react';
import "./Contact.css";
import contact_icon from '../../Assets/contact.png';

function Contact() {
  return (
    <>
      <div className='vertical-center'>
        <div className='container'>
          <div className='row align-items-center justify-content-center px-5 px-md-0 my-5 my-md-0'>            
            <div className='d-none d-md-block col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
              <img className='img-fluid mx-auto d-block contact-icon' src={contact_icon}/>
            </div> 

            <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 offset-md-2 contact-text'>
              <h1 className='text-center text-md-start'>Contact us!</h1>
              <br></br>
              <img className='d-md-none mb-5 img-fluid mx-auto d-block contact-icon' src={contact_icon}/>
              <div className='text-center text-md-start'>
                <p>Got any questions?<br></br>Feel free to tell us your inquiries
                and concerns!</p>
                <br></br>
                <p><a href='https://goo.gl/maps/1M4wKeRbV6s73z1N9' className='contact-info' target='_blank'><span style={{fontWeight:'600'}}>Address: </span> 
                    301 The Greenery, Pope John Paul II Avenue, Kasambagan, Cebu City, 
                    Philippines</a></p>
                {/* <p><a href="tel:+639173276230" className='contact-info' target='_blank'><span style={{fontWeight:'600'}}>Contact number:</span> +63 917 327 6230</a></p> */}
                <br></br>

                <a href='mailto:info@mytsoftdevsolutions.com' target='_blank' style={{color:'inherit'}}>
                <button type='button' className='btn btn-contact'>
                  info@mytsoftdevsolutions.com
                </button>
                </a>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </>
  );
}

export default Contact;