import React from 'react'
import "./BlogPost.css";

import west from "../../Assets/b43/west.jpg";

function Blog43() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Sharing is Caring: Design Process using Figma </h1>
                <h6>March 11, 2024 at 2:31 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>   
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={west} className="blog-img" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>It was an honor to have been invited by West Visayas State University-Himamaylan City Campus - 
                        School of Information & Communications Technology to share our knowledge on the Design Process using Figma. </p>
                    <p>Engaging with students who are eager to learn is always inspiring for us, as is imparting practical knowledge
                        that we hope will inspire them too in their future careers.  ✨</p>
                    <p><a href='https://www.facebook.com/hashtag/designprocess' target='_blank' className='blog-link'>
                        #DesignProcess </a>
                        <a href='https://www.facebook.com/hashtag/figmadesigning' target='_blank' className='blog-link'>
                        #FigmaDesigning </a>
                        <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
                        #ContinuousLearning </a>
                        <a href='https://www.facebook.com/hashtag/careerdevelopment' target='_blank' className='blog-link'>
                        #CareerDevelopment </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog43
