import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b3/img1.jpg';

function Blog3(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Training on Docker and Python</h1>
            <h6>June 08, 2022 at 08:25 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 text-center'>
            <img src={img1} className="blog-img"/>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>"Commit yourself to lifelong learning. The most valuable asset you'll
            ever have is your mind and what you put into it." -Brian Tracy</p>
            <p>We would like to extend our gratitude to Mr. Rommel Paras, a Senior
            Systems Engineer of Accel Robotics who led the training program on
            Docker and Python last April 5 & 7, 2022.</p>
            <p>In MYT, continuous learning is an important part of our company
            culture. We have trainings and workshops that can help you have a
            better grasp of problems you may encounter as well as address those
            problems for which you previously had no solution to.</p>
            <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='blog-link'>
              #ModuleZero </a>
              <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
              #ContinuousLearning</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog3;