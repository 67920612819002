import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b57/heroshot.jpg"

function Blog57() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>LeaseSync: Smart Solution for Seamless Leasing Operations</h1>
                    <h6>July 29, 2024 at 6:19 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Are you constantly battling with uncollected billings, missed renewals and piles of paperworks? LeaseSync is here to transform your property management experience, offering a digital solution that automates every step of the leasing process, from tenant onboarding to billing and collection.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/leasesync' target='_blank' className='blog-link'>
                        #LeaseSync </a>
                        <a href='https://www.facebook.com/hashtag/propertymanagement' target='_blank' className='blog-link'>
                        #PropertyManagement </a>
                        <a href='https://www.facebook.com/hashtag/smartsolutions' target='_blank' className='blog-link'>
                        #SmartSolutions </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog57
