import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b2/img1.jpg';
import img2 from '../../Assets/b2/img2.jpg';
import img3 from '../../Assets/b2/img3.jpg';
import img4 from '../../Assets/b2/img4.jpg';
import img5 from '../../Assets/b2/img5.jpg';
import img6 from '../../Assets/b2/img6.jpg';

function Blog1(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Distributing Water Bottles to Typhoon Odette Victims</h1>
            <h6>December 24, 2021 at 06:53 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img5} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img6} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Bangon ta Cebu!</p>
            <p>Today, the MYT Softdev Solutions and&nbsp;
              <a href='https://www.facebook.com/errandboy.ph' target='_blank' className='blog-link'>
              ErrandBoy.PH</a>
              &nbsp;team, family, and friends were able to give out almost 300 
              distilled water and mineral water bottles to families, children, and 
              senior citizens at The Greenery, Cebu City, Cebu.</p>
            <p>We would like to extend our heartfelt gratitude to our clients who
              have helped us and contacted us after Typhoon Odette struck Cebu. They
              have sent us multiple stocks of drinking water for those in need.</p>
            <p>The team will be having another distribution this coming Monday. For
              more information on donations and volunteering, you may contact us
              through our page or email us at&nbsp; 
              <a href='mailto:info@mytdevcorp.com' target='_blank' className='blog-link'>
              info@mytdevcorp.com</a>.</p>
            <p>Bangon ta Sugboanon! Bangon ta Cebu!</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog1;