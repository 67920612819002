import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b54/heroshot.jpg"

function Blog54() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Diagnosys: Smart Tools for Efficient Diagnostic Centers</h1>
                    <h6>July 18, 2024 at 4:33 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Say goodbye to administrative burdens, long wait times, and data management issues. Experience a boost in efficiency and precision with DiagnoSys — a system that offers smart tools designed to streamline income and expense tracking, seamless laboratory results releasing, comprehensive diagnostic reports, detailed patient assessment histories, and an efficient queueing system.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/diagnosys' target='_blank' className='blog-link'>
                        #DiagnoSys </a>
                        <a href='https://www.facebook.com/hashtag/diagnosticcenters' target='_blank' className='blog-link'>
                        #DiagnosticCenters </a>
                        <a href='https://www.facebook.com/hashtag/smarttools' target='_blank' className='blog-link'>
                        #SmartTools </a>
                        <a href='https://www.facebook.com/hashtag/efficienthealthcare' target='_blank' className='blog-link'>
                        #EfficientHealthcare </a>
                        <a href='https://www.facebook.com/hashtag/godigital' target='_blank' className='blog-link'>
                        #GoDigital </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog54
