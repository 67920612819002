import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b7/img1.jpg';

function Blog7(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Intellicare: MYT Benefits Orientation</h1>
            <h6>August 22, 2022 at 04:05 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 text-center'>
            <img src={img1} className="blog-img"/>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>We would like to extend our gratitude to&nbsp;
            <a href='https://www.facebook.com/intellicare/' target='_blank' className='blog-link'>
              Intellicare</a>
            &nbsp;for hosting an orientation about their Health Insurance Coverage
            and Benefits last August 3, 2022.</p>
            <p>In MYT, we value our team’s health and well-being. It is essential
            that they are aware of its coverage and how they can fully maximize
            the benefits.</p>
            <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/intellicare' target='_blank' className='blog-link'>
              #Intellicare </a>
              <a href='https://www.facebook.com/hashtag/healthiswealth' target='_blank' className='blog-link'>
              #HealthisWealth</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog7;