import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b14/img1.jpg';
import img2 from '../../Assets/b14/img2.jpg';

function Blog14(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Season of Giving</h1>
            <h6>December 12, 2022 at 6:02 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Tis the season to be jolly and to give back! 🎄</p>
            <p>MYT celebrates Christmas with UP Cebu Liadlaw Dormers! Today, the company turned over three brand new computers to the dormers. The brand new computers will surely aid the dormers in their academic requirements. 💻</p>
            <p>Here in MYT, we firmly believe in paying it forward and to providing avenues of growth for aspiring young professionals. 🌟</p>
            <p><a href='https://www.facebook.com/hashtag/payitforward' target='_blank' className='blog-link'>
              #PayItForward </a>
              <a href='https://www.facebook.com/hashtag/seasonofgiving' target='_blank' className='blog-link'>
              #SeasonofGiving </a>
              <a href='https://www.facebook.com/hashtag/mytfoundation' target='_blank' className='blog-link'>
              #MYTFoundation </a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog14;