import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b21/img1.jpg';
import img2 from '../../Assets/b21/img2.jpg';
import img3 from '../../Assets/b21/img3.jpg';
import img4 from '../../Assets/b21/img4.jpg';

function Blog21(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Sa Ngalan Sa Amahan</h1>
            <h6>April 1, 2023 at 10:15 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>"Explore new possibilities. What is the norm, might not be the good."</p>
            <p>A story that shed light on societal issues. A play of sadness, laughter, and tears. “Sa Ngalan sa Amahan” is a compelling, powerful, and emotional theatrical drama play presented by the UP Students' Theater Arts Guild for Education (UPSTAGE) of UP Cebu. It touched and inspired our hearts in MYT SoftDev Solutions last March 24, 2023.</p>
            <p>~~~ </p>
            <p>MYT also celebrates and supports the several talents of the UP Cebu community - a home of local artists who moves the audience with their emotions, wit, and talent.</p>
            <p>Congratulations to the UPSTAGE team. Indeed, a worthwhile performance. 👏👏💯</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog21;