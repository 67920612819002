import React from 'react';
import "./BlogPost.css";
import emrplus from "../../Assets/b34/EMR plus.png";
import twentyseven from "../../Assets/b34/27.png";
import forty from "../../Assets/b34/40.png";
import kai from "../../Assets/b34/kai.jpg";
import important from "../../Assets/b34/EMR IMPORTANT THINGS YOU NEED TO KNOW.png";

function Blog34(){
    return (
      <>
        <div className='container-fluid my-5'>
          <div className='row mb-5'>
            <div className='col blog-text text-center'>
              <h1>Paper Medical Records: Are they really reliable?</h1>
              <h6>- Kai Lo</h6>
            </div>
          </div>
        </div>

        <div className='container-fluid mb-2 mb-4'>
        <div className='row justify-content-center mb-4'>
          <div className='col-11 col-md-11 blog-text text-start text-md-justify'>
            <h3>PMRs remain to be the #1 tool of documentation</h3>
            <p>Paper medical records or also known as PMRs is any information of a patient recorded in paper like a chart. 
                PMRs contain patient Information that may include but not limited to family history, laboratory results, 
                medical treatment and diagnosis.  It remains to be the standard documentation tool for consultations.</p>
            <p>Despite advancements in the different fields of medical science such as robotics in rehab, 3D printing in
                creating implants and surgery, and so much more, according to Chapters 2 and 4 of the World Health Organization. 
                Regional Office for the Western Pacific (2006), </p>
            <p className='health-text'>“Health recording remains to be manual in developing countries like the Philippines.”</p>     {/* italic */}
            <p>So, why is there not much development in the area of health systems or software solutions in the Philippines 
                medical field? Based on the medical societies, this is because of the lack of technological infrastructure 
                in the Philippines, the high cost to transition to digital, and low support from the government. 
                Simply put, the majority of healthcare physicians are not aware of the presence of new technology, 
                and do not know where to acquire one.</p>
          </div>
        </div>

        <div className='row justify-content-center mb-4'>
          <div className='col-11 col-md-11 blog-text text-start text-md-justify'>
            <h3>Benefits of PMRs</h3>
            <p>PMRs have been the go-to patient recording for over centuries because of the many benefits it gives to physicians. 
                It has low upfront costs, has a familiar format and is easy to customise. </p>
            <div className='image-container mt-3 mb-3'><img src={twentyseven} className='twentyseven'></img></div>
            <h5>Low Upfront Cost</h5>
            <p>Having a PMR as a patient record is affordable to start with. One only needs a pen and paper to start with. 
                Also, some form of storage area for the paper records like a cabinet or a shelf, sometimes, it can even just 
                be a box especially if the physician has less patients at the start. However, when patients increase after 
                several years, the box may not be enough to contain the records anymore.</p>
            <h5>Familiarity to the format</h5>
            <p>Since paper medical records is the familiar format, the process of record keeping becomes routinary and convenient
                for the medical secretaries at the same time, the physician. What usually happens is the patient comes in, 
                writes down their personal information and brings the PMR for consultation inside the physician's office. 
                The critical key here is to organise neatly and legibly so that when the patient comes back, it is easy to
                recover the patient history. </p>
            <h5>Easy to customise</h5>
            <p>Whenever the healthcare professional decides to change or update their patient records, there is no need to call 
                a tech specialist. The doctor can just print a new form with the updated information that he needs. For example, 
                I have met a pulmonologist before and he decided to optimise the consultation flow. The patient record already has 
                a medical questionnaire on the symptoms of the patient  embedded. This allows patients to answer while waiting for 
                their turn and when they come inside the clinic, the doctor can easily make correlations and help with the diagnosis.</p>
            <p>Yes, PMRs are affordable to start, it is a familiar format and easy to customise but in reality, they also pose potential 
                risk and challenges for the healthcare practitioners and their patients. </p>
          </div>
        </div>

        <div className='row justify-content-center mb-4'>
          <div className='col-11 col-md-11 blog-text text-start text-md-justify'>
            <h3>Realities of PMR</h3>
            <p>I have met several healthcare practitioners in the field and they shared their experience. This is especially true for 
                those physicians who have been in practice for a while now and have gathered quite a number of patients already, 
                thus having a lot of patient records as well. Their medical secretaries share that though patient records are easy 
                and convenient at first, but in the long run, they pose several risks and challenges.</p>
            <div className='image-container mt-3 mb-3'><img src={forty} className='twentyseven'></img></div>
            <h5>Limited storage / not scalable</h5>
            <p>Storage is dependent on the cabinet space available. It is manageable at first when patients are few but after several 
                years, patients grow in number at the same time, each patient may also contain more information such as laboratory 
                results, medical treatment history and diagnosis that the cabinet space is no longer enough. I have experienced visiting
                 a clinic and the patient records are overflowing that they are already sitting on the bench.</p>
            <h5>Lack of backup and limited security</h5>
            <p>PMRs, since they are made of paper, are subject to fire, typhoon and flood. Sometimes, they are at risk for mice infestation.
                Once lost, they cannot be recovered.</p>
            <h5>Time consuming</h5>
            <p>This is especially true for returning patients. Medical secretaries, since they have a lot on their mind, are challenged in 
                neatly organising the records that when they need to patient progress and history arises, it becomes time consuming. </p>
            <h5>Risk for inaccuracy and errors</h5>
            <p>Physicians have a hectic schedule everyday to attend to. They have meetings, admissions, lectures and of course, consultations. 
                During checkups, they are reliant on the patient records for the past medical history so records are very crucial to be present
                 and at the same time, legible. Otherwise, there's a risk for errors on diagnosis and treatment.</p>
            <p>Overall,  these challenges decrease the quality of care provided to patients -especially to return patients- and decrease 
                the efficiency of physicians  that may result in unwanted long term impact to your practice.</p>
          </div>
        </div>

        <div className='row justify-content-center mb-4'>
          <div className='col-11 col-md-11 blog-text text-start text-md-justify'>
            <h3>What can be done to avoid risk</h3>
            <p>Paper medical records may have challenges but they can be managed. There are several solutions you can employ so that your
                 practice will have a smooth overall clinical experience for you and your patients.</p>
            <h5>Have an organised workflow</h5>
            <p>Have a systematic flow in place where the medical secretaries are in full support and commitment to the overall clinical practice.
                 Ensure that from history taking of the patients to consultation and even after storage, PMRs are written legibly and neatly 
                 organised. A mutual understanding between you and the secretary of the important things to be done at all times is critical 
                 so that nothing is left out. </p>
            <h5>Employ durable storage</h5>
            <p>When the records are neatly filed and organised, they should be placed in a durable storage so that when there is flood or mice, 
                the papers are safe inside the cabinets. There are a lot of fireproof filing cabinets available that you can install with the help 
                of trusted third party providers.</p>
          </div>
        </div>

        <div className='row justify-content-center mb-4'>
          <div className='col-11 col-md-11 blog-text text-start text-md-justify'>
            <h3>Switch to digital</h3>
            <p>Globally, more and more healthcare practitioners now have switched to electronic medical records because they have seen the long term
                 benefits of digital compared to paper medical records. And, you can also do the same. With EMR, it addressed the challenges for risk
                  for patient errors because this time around, you are sure that records will be legible all the time, there is enough storage for 
                  data that saves up clinic space and you can search for patient history within seconds! EMR also helps you access patient data 
                  anywhere, anytime and patient records are more secured from fire, flood, mice infestation. Hence, it doesn't just digitize the 
                  paper medical records, it helps optimise the overall clinical practice.</p>
            <p>Even though EMRs may not be as widely used in the Philippines, there are a number of options available now. Each EMR has different
                 features and benefits. Make sure to choose the right EMR product and the software provider that will aptly provide you with the key 
                 features that you are looking for. </p>
            <div className='image-container mt-3 mb-3'><img src={emrplus} className='twentyseven'></img></div>
            <p>Yes, paper medical records are commonly used in our country because of the benefits it gives to our healthcare professionals. 
                However, in reality, it has challenges and poses risk for error to patients and the physicians clinical practice in the long run. 
                Though these risks can be managed, a wise option is to transition to electronic medical records. </p>
            <p>Open to learn more about EMR?  You can book a demo with us to learn more about MedEase' EMR+. </p>
            <div className='image-container mt-3 mb-5'><img src={important} className='twentyseven'></img></div>
          </div>
        </div>

        {/* <div className='row justify-content-center'>
          <div className='col-11 col-md-11 blog-text text-start text-md-justify'>
            <h3>About thr Author</h3>
            <h2>KAI LO</h2>
            <p></p>
            <p></p>
            <p></p>
            <div><img src={emrplus} className='twentyseven'></img></div>
            <p></p>
            <p> </p>
          </div>
        </div> */}

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
              <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={kai} className="blog-img" alt="..."/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                <h4>About the Author</h4>
                <h2>KAI LO</h2>
                <p>With over 12 years of experience in sales, primarily in the pharma industry, I have gained valuable insights into the world 
                    of the medical industry. I have also worked briefly with Robin des Bois - APAC, a sustainable marketing agency from which I
                     discovered that on top of profit a business needs to have purpose. With additional affiliation in insurance and realty, 
                     now I have a well-rounded perspective of business and service needs.</p>
                <p>All throughout my professional career, I have seen the power of healthcare greatly impacting people’s lives. So, With MedEase, 
                    I aim to help in that cause by opening opportunities for healthcare professionals to optimize consultations and laboratory 
                    procedures through integrating innovative technology, so they in turn, can provide quality health care to patients.</p>
                <p>Follow her on her social media <a href=' https://www.facebook.com/kathleya.kay.lo' target='_blank' className='blog-link'>
                    https://www.facebook.com/kathleya.kay.lo.</a></p>
            </div>
          </div>

      </div>
      </>
    );
  }
  
  export default Blog34;