import React from 'react';
import "./BlogPost.css";

import heroshot from "../../Assets/b39/heroshot.png"
import img1 from "../../Assets/b39/img1.jpg"
import img2 from "../../Assets/b39/img2.jpg"
import img3 from "../../Assets/b39/img3.jpg"
import img4 from "../../Assets/b39/img4.jpg"
import img5 from "../../Assets/b39/img5.jpg"

function Blog39() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Sinulog Festival!</h1>
                    <h6>January 20, 2024 at 3:34 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={heroshot} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img1} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img2} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img3} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img4} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img5} className="blog-img" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>MYT team takes the streets of Sinulog, celebrating the spirit of camaraderie, shared victories, and a colorful journey ahead 🎉🎶</p>
                    <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                        #MYTCulture </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog39
