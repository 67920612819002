import React from 'react'
import "./BlogPost.css";

import leap1 from '../../Assets/b44/leap1.jpg';
import leap2 from '../../Assets/b44/leap2.jpg';
import leap3 from '../../Assets/b44/leap3.jpg';
import leap4 from '../../Assets/b44/leap4.jpg';
import leap5 from '../../Assets/b44/leap5.jpg';

function Blog44() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Just Leap! 🏃🏻</h1>
                <h6>March 22, 2024 at 10:30 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                    <div className="carousel-indicators">
                    </div>    
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={leap1} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={leap2} className="blog-img" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={leap3} className="blog-img" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={leap4} className="blog-img" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={leap5} className="blog-img" alt="..."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>The team took a LEAP to new heights during the Kick-Off, where we charted new paths to success and leaped together
                        beyond boundaries! 🚀 The energy and creativity were boundless, setting the stage for our journey ahead. 💪</p>
                    <p>
                        <a href='https://www.facebook.com/hashtag/leapcamp' target='_blank' className='blog-link'>
                        #LeapCamp </a>
                        <a href='https://www.facebook.com/hashtag/chartingnewpaths' target='_blank' className='blog-link'>
                        #ChartingNewPaths </a>
                        <a href='https://www.facebook.com/hashtag/teamsuccess' target='_blank' className='blog-link'>
                        #TeamSuccess </a>
                        <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                        #MYTCulture </a>
                        <a href='https://www.facebook.com/hashtag/kickoff' target='_blank' className='blog-link'>
                        #KickOff </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog44
