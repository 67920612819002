import React from 'react';
import "./BlogPost.css";
import mytguardians from '../../Assets/b26/mytguardians.JPG';

function Blog26(){
    return (
        <>
        <div className='container-fluid my-5'>
          <div className='row mb-5'>
            <div className='col blog-text text-center'>
              <h1>MYT meets the Guardians of the Galaxy</h1>
              <h6>May 29, 2023 at 2:14 PM</h6>
            </div>
          </div>
  
          <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
              <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                <img src={mytguardians} className="blog-img" alt="..."/>
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                <p>We're the MYT SoftDev Solutions Guardians of the Galaxy! 🌌✨</p>
              <p>Our team took a cosmic detour from coding to embark on an epic team-building adventure at the movies, watching Guardians of the Galaxy! 🎥🍿 Together, we laughed, cheered, and even danced to some awesome '80s tunes! 🎶</p>
              <p>Just like the Guardians, our team knows the power of unity and collaboration. We're ready to conquer any development challenge that comes our way.</p>
              <p>Collaborative problem-solving sessions brought together the best minds from different schools, fostering teamwork and camaraderie among the coding community. 👥🌐</p>
              <p>Join us on this interstellar journey as we continue to deliver out-of-this-world solutions and create the brightest tech galaxy imaginable! 🚀✨💫 </p>
              <p><a href='https://www.facebook.com/hashtag/muyculture' target='_blank' className='blog-link'>
                #MYTCulture </a>
                <a href='https://www.facebook.com/hashtag/worklifebalance' target='_blank' className='blog-link'>
                #WorkLifeBalance </a>
                <a href='https://www.facebook.com/hashtag/guardiansofthegalaxy' target='_blank' className='blog-link'>
                #GuardiansOfTheGalaxy </a>
                <a href='https://www.facebook.com/hashtag/teambuilding' target='_blank' className='blog-link'>
                #TeamBuilding </a>
              </p> 
            </div>
          </div>
        </div>
      </>
    );
}
export default Blog26;