import React from 'react';
import "./BlogPost.css";

import heroshot from "../../Assets/b37/heroshot.jpg"
import img1 from "../../Assets/b37/img1.jpg"
import img2 from "../../Assets/b37/img2.jpg"
import img3 from "../../Assets/b37/img3.jpg"
import img4 from "../../Assets/b37/img4.webp"
import img5 from "../../Assets/b37/img5.jpg"
import img6 from "../../Assets/b37/img6.jpg"
import img7 from "../../Assets/b37/img7.jpg"
import img8 from "../../Assets/b37/img8.jpg"
import img9 from "../../Assets/b37/img9.jpg"
import img10 from "../../Assets/b37/img10.jpg"

function Blog37() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>MYT's Yuletide Year-Ender: When in Boracay!</h1>
                    <h6>December 24, 2023 at 9:39 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="8" aria-label="Slide 9"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="9" aria-label="Slide 10"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="10" aria-label="Slide 11"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={heroshot} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img1} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img2} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img3} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img4} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img5} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img6} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img7} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img8} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img9} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img10} className="blog-img" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Elevating the holiday spirit with our incredible team – Merry Christmas from MYT Family to yours! 🎉🎄 <br/><br/>Unwrapping joy and festive cheer under the Boracay sun, MYT's Christmas party in paradise was a magical blend of laughter, tropical delights, and unforgettable moments that created warm memories for the entire team.</p>
                    <p><a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='blog-link'>
                        #ModuleZero </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog37
