import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b47/heroshot.jpg"

function Blog47() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>12 Years of Transforming Visions into Solutions</h1>
                    <h6>July 2, 2024 at 8:45 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>For 12 years, MYT Solutions has been dedicated to turning visions into solutions and creating innovative software. Each software solution has been crafted with our clients' needs in mind, enhancing business process and driving success. ✨</p>
                    <p><a href='https://www.facebook.com/hashtag/mytislit' target='_blank' className='blog-link'>
                        #MYTisLit </a>
                        <a href='https://www.facebook.com/hashtag/myt12years' target='_blank' className='blog-link'>
                        #MYT12Years </a>
                        <a href='https://www.facebook.com/hashtag/visionstosolutions' target='_blank' className='blog-link'>
                        #VisionsintoSolutions </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog47
