import React from 'react'
import "./BlogPost.css";

import hei from "../../Assets/b41/hei.jpg"

function Blog41() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>KUNG HEI FAT CHOI</h1>
                <h6>February 10, 2024 at 7:16 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={hei} className="blog-img" alt="..." />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Entering the Year of the Dragon, we leap forward with prosperity and fruitful collaborations in business.
                        Wishing all ventures abundance and opportunities! 🐉</p>
                    <p><a href='https://www.facebook.com/hashtag/lunarnewyear' target='_blank' className='blog-link'>
                        #LunarNewYear </a>
                        <a href='https://www.facebook.com/hashtag/prosperouspartnerships' target='_blank' className='blog-link'>
                        #ProsperousPartnerships </a>
                        <a href='https://www.facebook.com/hashtag/myt' target='_blank' className='blog-link'>
                        #MYT </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog41
