import React from 'react';
import home_pc from '../../Assets/home-pc.png';
import worldmap from '../../Assets/worldmap.png';
import './Home.css';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <>
      <div className='container-fluid my-5 px-4'>
        <div className='row align-items-center justify-content-lg-center justify-content-md-around pb-3'>
          <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ms-lg-5 ps-lg-5 home-text'>
            <h1 className='text-center text-md-start'>INNOVATIVE. <br></br> <span class='dark-text'>INTUITIVE.</span> <br></br> POWERFUL.</h1>
            <br></br>
            <img className='d-md-none mb-5 img-fluid mx-auto d-block home-image' src={home_pc}/>
            <p>
              MYT provides website, custom software, mobile apps, and graphics design for your business. <br></br><br></br>
              We aim to create a competitive edge for your business for better 
              <span class='bold'> productivity, profitability,</span> and <span class='bold'>sustainability.</span>
            </p>
          </div>
          <div className='d-none d-md-block col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 ms-lg-5'>
            <img className='img-fluid home-image text-center text-md-start' src={home_pc}/>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-center mt-5 pb-5'>
          <Link to="/about"><button type='button' className='btn btn-learn'>Learn more</button></Link>
        </div>
      </div>

      <div className='container-fluid py-5 rounded-top-left rounded-top-right map-text' style={{backgroundColor: "#4fc0e8"}}>
        <div className='row'>
          <div className='col text-center'>
            <h1>We've served clients from all over the globe</h1>
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div className='col text-center'>
            <div className='map-image'><img src={worldmap}/></div>
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div className='col text-center'>
          <p>Philippines, Singapore, Indonesia, USA, UK, and Australia</p>
          </div>
        </div>
      </div>

      <div className='container-fluid py-5 my-5 stats-text'>
        <div className='row'>
          <div className='col text-center'>
            <h1>Over the last decade we have</h1>
          </div>
        </div>
        <br></br>
        <div className='row align-items-center justify-content-center'>
          <div className='col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3 gy-4 text-center'>
            <h2><span class='stat'>100+</span><br></br>Projects Deployed</h2>
          </div>
          <div className='col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3 gy-4 text-center'>
            <h2><span class='stat'>50+</span><br></br>Clients</h2>
          </div>
          <div className='col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3 gy-4 text-center'>
            <h2><span class='stat'>10+</span><br></br>In-House Development</h2>
          </div>
        </div>
      </div>
        
      <div className='container-fluid py-3 mt-5' style={{backgroundColor: "#4fc0e8"}}>
        <div className='row'>
          <div className='col text-center company-num'>
            <span className='dark-text'>We have partnered with more than </span>50+ companies
          </div>
        </div>
      </div>

      <div class="scrolling-image-container">
        <div class="scrolling-image"></div>
      </div>
    </>
  );
}

export default Home;