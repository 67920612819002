import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b20/img1.jpg';
import img2 from '../../Assets/b20/img2.jpg';
import img3 from '../../Assets/b20/img3.jpg';
import img4 from '../../Assets/b20/img4.jpg';
import img5 from '../../Assets/b20/img5.jpg';
import img6 from '../../Assets/b20/img6.jpg';
import img7 from '../../Assets/b20/img7.jpg';


function Blog20(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Women in Tech</h1>
            <h6>March 8, 2023 at 5:06 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="6" aria-label="Slide 7"></button>
              </div>
              <div className="carousel-inner">
              <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img5} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img6} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img7} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Hi, Tin here 👩‍🚀 </p>
            <p>Happy International Women's Day! 💜 </p>
            <p>Today is a celebration for every woman out there who's reaching their goals, conquering milestones, and being role models in society especially in technology ✨ </p>
            <p>Oh and you bet! Young girls like you and I have a place in technology. I once started as an intern with zero skills in the industry but I had a  big heart and willingness to learn the ins and outs of the tech industry. Now, I'm a thriving Technical Project Manager with skills in frontend development, client relationship, team management and so much more! 🫡 </p>
            <p>Now, let's hear from the women of MYT, my colleagues and people I look up to, on how they encourage young girls into technology and in the fields that they're in.  🙇‍♀️</p>
            <p><a href='https://www.facebook.com/hashtag/womenintech' target='_blank' className='blog-link'>
              #WomenInTech </a>
              <a href='https://www.facebook.com/hashtag/internationalwomensday' target='_blank' className='blog-link'>
              #internationalwomensday </a>
              <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog20;