import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b40/heroshot.jpg"
import img1 from "../../Assets/b40/img1.jpg"
import img2 from "../../Assets/b40/img2.jpg"
import img3 from "../../Assets/b40/img3.jpg"

function Blog40() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>LEAP CAMP: Take the First Step <br /> "2024 Pre-kickoff Session"</h1>
                <h6>January 30, 2024 at 10:00 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={heroshot} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img1} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img2} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img3} className="blog-img" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>As we LEAP INTO POSSIBILITIES this 2024, the team will collectively LEAP UP and confidently LEAP FORWARD 🚀 We LEAP INTO THE FUTURE with shared aspirations and growth 🌱</p>
                    <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                        #MYTCulture </a>
                        <a href='https://www.facebook.com/hashtag/mytislit' target='_blank' className='blog-link'>
                        #MYTisLIT </a>
                        <a href='https://www.facebook.com/hashtag/teambuilding' target='_blank' className='blog-link'>
                        #TeamBuilding </a>
                        <a href='https://www.facebook.com/hashtag/prekickoff2024' target='_blank' className='blog-link'>
                        #PreKickOff2024 </a>
                        <a href='https://www.facebook.com/hashtag/leap' target='_blank' className='blog-link'>
                        #LEAP </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog40
