import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b12/img1.jpg';
import img2 from '../../Assets/b12/img2.jpg';
import img3 from '../../Assets/b12/img3.jpg';
import img4 from '../../Assets/b12/img4.jpg';

function Blog12(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>November Dump</h1>
            <h6>November 14, 2022 at 8:00 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>In MYT, it is a vital part in our culture to maintain a work-life balance and sharing such precious moments together strengthens our relationship.</p>
            <p>Last Saturday, the MYT team had a full-packed day from watching the Black Panther: Wakanda Forever movie showing to singing our hearts out at Hello KTV. </p>
            <p>The team also awarded the new graduates from the Module 0 Internship Program. Congratulations to our UC interns — Niño, Kevin, and Shaunley!</p>
            <p>Lastly, the team celebrated the November birthday celebrants — Clint, Novel, Alex and Tin. </p>
            <p>Memories and birthdays. Another page in our story. </p>
            <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/worklifebalance' target='_blank' className='blog-link'>
              #WorkLifeBalance </a>
              <a href='https://www.facebook.com/hashtag/healthyworkingenvironment' target='_blank' className='blog-link'>
              #HealthyWorkingEnvironment </a>
              <a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='blog-link'>
              #ModuleZero </a>
              <a href='https://www.facebook.com/hashtag/blackpanther' target='_blank' className='blog-link'>
              #BlackPanther </a>
              <a href='https://www.facebook.com/hashtag/karaoke' target='_blank' className='blog-link'>
              #karaoke </a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog12;