import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b5/img1.jpg';
import img2 from '../../Assets/b5/img2.jpg';
import img3 from '../../Assets/b5/img3.jpg';

function Blog5(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Front-End Development Workshop</h1>
            <h6>August 08, 2022 at 12:10 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Gone are the days when developers have to code full, multipage
            applications. 😰</p>
            <p>Nowadays, it is typical to create a single-page system of elements
            and components. This approach lets us create a flexible, shareable,
            and consistent interface system. </p>
            <p>Each time a new frontend developer is assigned in a project, they
            have to spend time familiarizing what has already been created and
            how the pieces work together. Referring to a front-end style guide
            can speed up this process — ✨ A LOT ✨.</p>
            <p>MYT aims to standardize the front-end structure for its current and
            upcoming projects in terms of file and folder conventions, frameworks,
            libraries, coding styles, and components. A face-to-face workshop was
            presented by Miss Gwyneth Chiu which was held last July 22, 2022 for
            all employees and interns. </p>
            <p><a href='https://www.facebook.com/hashtag/careerdevelopment' target='_blank' className='blog-link'>
              #CareerDevelopment </a>
              <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
              #ContinuousLearning </a>
              <a href='https://www.facebook.com/hashtag/frontendstyleguide' target='_blank' className='blog-link'>
              #FrontendStyleGuide </a>
              <a href='https://www.facebook.com/hashtag/frontenddevelopmentworkshop' target='_blank' className='blog-link'>
              #FrontEndDevelopmentWorkshop </a>
              <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog5;