import React from 'react'
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import mytlogo from '../../Assets/myt logo.png'

function Navbar() {
    return (
      <div className='navbar navbar-expand-lg navbar-light justify-content-between navtext sticky-top' style={{backgroundColor: "#f4f4f4"}}>
        <div className='d-none d-md-block navbar-brand'> 
          <img className='appLogo' src={mytlogo}/><span className='titleDiv'>MYT SoftDev Solutions Inc.</span>
        </div>
        <div className='d-md-none navbar-brand'> 
          <img className='appLogo' src={mytlogo}/>
        </div>
        <button type="button" className="navbar-toggler me-2" data-bs-toggle="collapse" data-bs-target="#mytNav" aria-controls="mytNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="mytNav">
          <div className='nav navbar-nav mt-2 mt-lg-0'>
            <NavLink to="/" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Home</NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>About</NavLink>
            <NavLink to="/services" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Services and Tech</NavLink>
            <NavLink to="/product" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Products</NavLink>
            <NavLink to="/blogs" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Blogs</NavLink>
            <NavLink to="/contact" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Contact Us</NavLink>
          </div>
        </div>
      </div> 
    );
  }
  
export default Navbar;