import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b53/heroshot.jpg"

function Blog53() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>FranchiSync: Integrated POS and Franchise Management System</h1>
                    <h6>July 15, 2024 at 8:46 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Managing a franchise is a challenge, but a system can simplify everything.</p>
                    <p>Introducing our FranchiSync — an integrated POS and franchise management system that optimizes franchisee billings, royalties, inventory, receivables and payables management for maximum operational efficiency across your franchise network.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/franchisync' target='_blank' className='blog-link'>
                        #FranchiSync </a>
                        <a href='https://www.facebook.com/hashtag/franchisemanagement' target='_blank' className='blog-link'>
                        #FranchiseManagement </a>
                        <a href='https://www.facebook.com/hashtag/godigital' target='_blank' className='blog-link'>
                        #GoDigital </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog53
