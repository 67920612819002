import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b10/img1.jpg';
import img2 from '../../Assets/b10/img2.jpg';
import img3 from '../../Assets/b10/img3.jpg';

function Blog10(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Upskilling Session 1: Design Process Using Figma</h1>
            <h6>October 20, 2022 at 06:38 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Beautiful, Engaging, and Smart. That's how designs should be!</p>
            <p>October 19 – MYT joins the&nbsp;
              <a href='https://www.facebook.com/UPCSG' target='_blank' className='blog-link'>
                UP Computer Science Guild</a>
              &nbsp;in upskilling talented young designers at the University of the
              Philippines - Cebu. Tin, a Technical Project Manager of the
              company, attended as a speaker of the event and discussed to
              young designers the skills of Design Process in Figma.</p>
            <p>MYT advocates for the continuous learning and growing of young
              professionals. With gratitude, the company heartily accepted the
              partnership with the UP Computer Science Guild in this educational
              endeavor of teaching young professionals on how to create beautiful,
              smart and effective designs and solutions.</p>
            <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='blog-link'>
              #ContinuousLearning </a>
              <a href='https://www.facebook.com/hashtag/designprocess' target='_blank' className='blog-link'>
              #DesignProcess </a>
              <a href='https://www.facebook.com/hashtag/figmadesigning' target='_blank' className='blog-link'>
              #FigmaDesigning</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog10;