import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b56/heroshot.jpg"

function Blog56() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Smart Registration as Easy as Click</h1>
                    <h6>July 25, 2024 at 4:07 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Welcome your attendees with open arms and a seamless experience. With our smart event registration system, everything is just a click away. From QR code generation to instant scanning, we've redefined ease and efficiency, allowing you to focus on creating moments that resonate with every attendee.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/smartregistration' target='_blank' className='blog-link'>
                        #SmartRegistration </a>
                        <a href='https://www.facebook.com/hashtag/godigital' target='_blank' className='blog-link'>
                        #GoDigital </a>
                        <a href='https://www.facebook.com/hashtag/qrcodescan' target='_blank' className='blog-link'>
                        #QRCodeScan </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog56
