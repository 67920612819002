import React from 'react';
import "./BlogPost.css";
import mytruns from "../../Assets/b25/mytruns.jpg";

function Blog25(){
    return (
        <>
        <div className='container-fluid my-5'>
          <div className='row mb-5'>
            <div className='col blog-text text-center'>
              <h1>A run for a cause</h1>
              <h6>May 26, 2023 at 10:03 AM</h6>
            </div>
          </div>
  
          <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
              <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                <img src={mytruns} className="blog-img" alt="..."/>
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
              <p>MYT runs together for a meaningful cause. 🏃‍♀️🏃‍♂️🎗️</p>
              <p>Joining the Run for Gift of Life as a team-building activity this May, our company stands united in support of this great cause. </p>
              <p>Collaborative problem-solving sessions brought together the best minds from different schools, fostering teamwork and camaraderie among the coding community. 👥🌐</p>
              <p>Together, we're building stronger bonds while making a difference in the lives of others. Let's run, inspire, and support the gift of life! ❤️</p>
              <p><a href='https://www.facebook.com/hashtag/muyculture' target='_blank' className='blog-link'>
                #MYTCulture </a>
                <a href='https://www.facebook.com/hashtag/teambuilding' target='_blank' className='blog-link'>
                #TeamBuilding </a>
                <a href='https://www.facebook.com/hashtag/fitness' target='_blank' className='blog-link'>
                #Fitness </a>
                <a href='https://www.facebook.com/hashtag/runforacause' target='_blank' className='blog-link'>
                #RunforGiftofLife </a>
                <a href='https://www.facebook.com/hashtag/runforgiftoflife' target='_blank' className='blog-link'>
                #RunforGiftofLife </a>
              </p> 
            </div>
          </div>
        </div>
      </>
    );
}
export default Blog25;