import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b28/aws1.jpg';
import img2 from '../../Assets/b28/aws2.jpg';
import img3 from '../../Assets/b28/aws3.jpg';

function Blog28(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Unveiling the power-packed partnership 🔥🔒</h1>
            <h6>June 26, 2023 at 4:22 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
             <p>Our company meeting with AWS is all about elevating our server services to new heights of excellence and state of the art infrastructure.</p>
             <p>Brace yourselves for a future filled with unparalleled performance and rock-solid protection. Join us on this thrilling journey! 💪🚀 </p>
             <p><a href='https://www.facebook.com/hashtag/awspartnership' target='_blank' className='blog-link'>
              #AWSPartnership</a>
              <a href='https://www.facebook.com/hashtag/nextlevelservices' target='_blank' className='blog-link'>
              #NextLevelServices</a>
              <a href='https://www.facebook.com/hashtag/gamechangers' target='_blank' className='blog-link'>
              #GameChangers</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog28;