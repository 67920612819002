import React from 'react';
import "./BlogPost.css";

import heroshot from "../../Assets/b38/heroshot.jpg"

function Blog38() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>MYT New Year Celebration!</h1>
                    <h6>January 15, 2024 at 3:04 PM</h6>
                </div>
            </div>

            <div className='container'>
                {/* <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="">
                            <div className="">
                                <img src={heroshot} className="blog-img" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div> */}

                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
            
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text p-5'>
                <p>Ready to conquer new challenges, embrace fresh opportunities, and make every moment count this 2024! 💪✨</p>
                <p><a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='blog-link'>
                    #ModuleZero </a>
                    <a href='https://www.facebook.com/hashtag/bringiton2024' target='_blank' className='blog-link'>
                        #BringItOn2024 </a>
                </p>
            </div>
            </div>
        </div>
    )
}

export default Blog38
