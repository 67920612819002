import React from 'react';
import "./BlogPost.css";
import intellicare33 from "../../Assets/b33/intellicare33.jpg";

function Blog33(){
    return (
      <>
        <div className='container-fluid my-5'>
          <div className='row mb-5'>
            <div className='col blog-text text-center'>
              <h1>Intellicare; Health Insurance and Coverage</h1>
              <h6>October 12, 2023 at 5:13 PM</h6>
            </div>
          </div>
  
          <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
              <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={intellicare33} className="blog-img" alt="..."/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
              <p>At MYT, we value the health and well-being of our employees,
                and ensuring that they are well-informed and covered is important to us. </p>
              <p>Thanks to <a href='https://www.facebook.com/intellicare' target='_blank' className='blog-link'>
              Intellicare </a> for hosting the insightful orientation about Health Insurance Coverage and Benefits.</p>
               <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                #MYTCulture </a>
                <a href='https://www.facebook.com/hashtag/intellicare' target='_blank' className='blog-link'>
                #Intellicare </a>
                <a href='https://www.facebook.com/hashtag/healthiswealth' target='_blank' className='blog-link'>
                #HealthIsWealth</a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default Blog33;