import React from 'react';
import "./BlogPost.css";
import nun from "../../Assets/b31/the-nun.jpg";

function Blog31(){
    return (
      <>
        <div className='container-fluid my-5'>
          <div className='row mb-5'>
            <div className='col blog-text text-center'>
              <h1>Fear Unleashed: The MYT Team vs. 'The Nun'</h1>
              <h6>September 12, 2023 at 4:02 PM</h6>
            </div>
          </div>
  
          <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
              <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={nun} className="blog-img" alt="..."/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
              <p>Leveling up our team-building activity with a spine-chilling twist
                as we bravely gathered to watch 'The Nun'. Team bonding at its spookiest! 👻</p>
               <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
                #MYTCulture </a>
                <a href='https://www.facebook.com/hashtag/teambuilding' target='_blank' className='blog-link'>
                #TeamBuilding </a>
                <a href='https://www.facebook.com/hashtag/halloweenmovienight' target='_blank' className='blog-link'>
                #HalloweenMovieNight </a>
                <a href='https://www.facebook.com/hashtag/worklifebalance' target='_blank' className='blog-link'>
                #WorkLifeBalance </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default Blog31;