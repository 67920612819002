import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import AboutUs from './pages/About/AboutUs';
import Home from './pages/HomePage/Home';
import Services from './pages/Services/Services';
import Product from './pages/Product/Product';
import Contact from './pages/Contact/Contact';
import Blogs from './pages/Blogs/Blogs';
import Blog1 from './pages/Blogs/Blog1';
import Blog2 from './pages/Blogs/Blog2';
import Blog3 from './pages/Blogs/Blog3';
import Blog4 from './pages/Blogs/Blog4';
import Blog5 from './pages/Blogs/Blog5';
import Blog6 from './pages/Blogs/Blog6';
import Blog7 from './pages/Blogs/Blog7';
import Blog8 from './pages/Blogs/Blog8';
import Blog9 from './pages/Blogs/Blog9';
import Blog10 from './pages/Blogs/Blog10';
import Blog11 from './pages/Blogs/Blog11';
import Blog12 from './pages/Blogs/Blog12';
import Blog13 from './pages/Blogs/Blog13';
import Blog14 from './pages/Blogs/Blog14';
import Blog15 from './pages/Blogs/Blog15';
import Blog16 from './pages/Blogs/Blog16';
import Blog17 from './pages/Blogs/Blog17';
import Blog18 from './pages/Blogs/Blog18';
import Blog19 from './pages/Blogs/Blog19';
import Blog20 from './pages/Blogs/Blog20';
import Blog21 from './pages/Blogs/Blog21';
import Blog22 from './pages/Blogs/Blog22';
import Blog23 from './pages/Blogs/Blog23';
import Blog24 from './pages/Blogs/Blog24';
import Blog25 from './pages/Blogs/Blog25';
import Blog26 from './pages/Blogs/Blog26';
import Blog27 from './pages/Blogs/Blog27';
import Blog28 from './pages/Blogs/Blog28';
import Blog29 from './pages/Blogs/Blog29';
import Blog30 from './pages/Blogs/Blog30';
import Blog31 from './pages/Blogs/Blog31';
import Blog32 from './pages/Blogs/Blog32';
import Blog33 from './pages/Blogs/Blog33';
import Blog34 from './pages/Blogs/Blog34';
import Blog35 from './pages/Blogs/Blog35';
import Blog36 from './pages/Blogs/Blog36';
import Blog37 from './pages/Blogs/Blog37';
import Blog38 from './pages/Blogs/Blog38';
import Blog39 from './pages/Blogs/Blog39';
import Blog40 from './pages/Blogs/Blog40';
import Blog41 from './pages/Blogs/Blog41';
import Blog42 from './pages/Blogs/Blog42';
import Blog43 from './pages/Blogs/Blog43';
import Blog44 from './pages/Blogs/Blog44';
import Blog45 from './pages/Blogs/Blog45';
import Blog46 from './pages/Blogs/Blog46';
import Blog47 from './pages/Blogs/Blog47';
import Blog48 from './pages/Blogs/Blog48';
import Blog49 from './pages/Blogs/Blog49';
import Blog50 from './pages/Blogs/Blog50';
import Blog51 from './pages/Blogs/Blog51';
import Blog52 from './pages/Blogs/Blog52';
import Blog53 from './pages/Blogs/Blog53';
import Blog54 from './pages/Blogs/Blog54';
import Blog55 from './pages/Blogs/Blog55';
import Blog56 from './pages/Blogs/Blog56';
import Blog57 from './pages/Blogs/Blog57';

function App() {
  return (
    <div className='app'>
      <Router>
        <ScrollToTop />
        <Navbar /> 
        <div className='main'>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<AboutUs/>} />
            <Route path="/services" element={<Services/>}/>
            <Route path="/product" element={<Product/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/blogs" element ={<Blogs/>}/>
            <Route path='/blogs/blog1' element={<Blog1/>}/>
            <Route path='/blogs/blog2' element={<Blog2/>}/>
            <Route path='/blogs/blog3' element={<Blog3/>}/>
            <Route path='/blogs/blog4' element={<Blog4/>}/>
            <Route path='/blogs/blog5' element={<Blog5/>}/>
            <Route path='/blogs/blog6' element={<Blog6/>}/>
            <Route path='/blogs/blog7' element={<Blog7/>}/>
            <Route path='/blogs/blog8' element={<Blog8/>}/>
            <Route path='/blogs/blog9' element={<Blog9/>}/>
            <Route path='/blogs/blog10' element={<Blog10/>}/>
            <Route path='/blogs/blog11' element={<Blog11/>}/>
            <Route path='/blogs/blog12' element={<Blog12/>}/>
            <Route path='/blogs/blog13' element={<Blog13/>}/>
            <Route path='/blogs/blog14' element={<Blog14/>}/>
            <Route path='/blogs/blog15' element={<Blog15/>}/>
            <Route path='/blogs/blog16' element={<Blog16/>}/>
            <Route path='/blogs/blog17' element={<Blog17/>}/>
            <Route path='/blogs/blog18' element={<Blog18/>}/>
            <Route path='/blogs/blog19' element={<Blog19/>}/>
            <Route path='/blogs/blog20' element={<Blog20/>}/>
            <Route path='/blogs/blog21' element={<Blog21/>}/>
            <Route path='/blogs/blog22' element={<Blog22/>}/>
            <Route path='/blogs/blog23' element={<Blog23/>}/>
            <Route path='/blogs/blog24' element={<Blog24/>}/>
            <Route path='/blogs/blog25' element={<Blog25/>}/>
            <Route path='/blogs/blog26' element={<Blog26/>}/>
            <Route path='/blogs/blog27' element={<Blog27/>}/>
            <Route path='/blogs/blog28' element={<Blog28/>}/>
            <Route path='/blogs/blog29' element={<Blog29/>}/>
            <Route path='/blogs/blog30' element={<Blog30/>}/>
            <Route path='/blogs/blog31' element={<Blog31/>}/>
            <Route path='/blogs/blog32' element={<Blog32/>}/>
            <Route path='/blogs/blog33' element={<Blog33/>}/>
            <Route path='/blogs/paper-medical-records' element={<Blog34/>}/>
            <Route path='/blogs/blog35' element={<Blog35/>}/>
            <Route path='/blogs/blog36' element={<Blog36/>}/>
            <Route path='/blogs/blog37' element={<Blog37/>}/>
            <Route path='/blogs/blog38' element={<Blog38/>}/>
            <Route path='/blogs/blog39' element={<Blog39/>}/>
            <Route path='/blogs/blog40' element={<Blog40/>}/>
            <Route path='/blogs/blog41' element={<Blog41/>}/>
            <Route path='/blogs/blog42' element={<Blog42/>}/>
            <Route path='/blogs/blog43' element={<Blog43/>}/>
            <Route path='/blogs/blog44' element={<Blog44/>}/>
            <Route path='/blogs/blog45' element={<Blog45/>}/>
            <Route path='/blogs/blog46' element={<Blog46/>}/>
            <Route path='/blogs/blog47' element={<Blog47/>}/>
            <Route path='/blogs/blog48' element={<Blog48/>}/>
            <Route path='/blogs/blog49' element={<Blog49/>}/>
            <Route path='/blogs/blog50' element={<Blog50/>}/>
            <Route path='/blogs/blog51' element={<Blog51/>}/>
            <Route path='/blogs/blog52' element={<Blog52/>}/>
            <Route path='/blogs/blog53' element={<Blog53/>}/>
            <Route path='/blogs/blog54' element={<Blog54/>}/>
            <Route path='/blogs/blog55' element={<Blog55/>}/>
            <Route path='/blogs/blog56' element={<Blog56/>}/>
            <Route path='/blogs/blog57' element={<Blog57/>}/>
          </Routes>
        </div>
        <Footer />
      </Router>
      
      <MessengerCustomerChat pageId="407439242649109" appId="646401747275332"/>
    </div>
  );
}

export default App;