import React from 'react'
import './Footer.css';
import mytFooterLogo from '../../Assets/myt logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Footer() {
  return (
    <footer className="d-flex justify-content-between" style={{backgroundColor: "#f4f4f4"}}>
      <div class="d-flex align-items-center">
        <img className='footerLogo' src={mytFooterLogo}/>
        <span className='footerTitle d-none d-md-block'>WE BUILD SMARTER STUFF.</span>
      </div>
      <ul class="col-md-4 align-items-center justify-content-end list-unstyled d-flex ms-2 ms-md-0 me-md-5">
        <li><a target="_blank" href='https://www.facebook.com/MYTSoftDevSolutions'><FontAwesomeIcon className='footerIcon' icon={faFacebook}/></a></li>
        <li><a target="_blank" href='https://www.linkedin.com/company/myt-software/about/'><FontAwesomeIcon className='footerIcon' icon={faLinkedin}/></a></li>
        <li><a target="_blank" href='mailto:info@mytsoftdevsolutions.com'><FontAwesomeIcon className='footerIcon' icon={faEnvelope}/></a></li>
      </ul>
    </footer>
  );
}

export default Footer;
