import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b50/heroshot.jpg"

function Blog50() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Medease PEDIA: Smarter Records for Bright Futures</h1>
                    <h6>July 8, 2024 at 5:41 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <img src={heroshot} className="blog-img" alt="..." />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Nurture each child's growth journey with MedEase-Pedia. Experience seamless patient data management, growth milestone tracking with our digital baby book, and simplified medicine and vaccine dispensing — all in our MedEase-Pedia system to ensure every child receives optimal care.</p>
                    <p>Contact us to see how it works!</p>
                    <p><a href='https://www.facebook.com/hashtag/medease' target='_blank' className='blog-link'>
                        #MedEase </a>
                        <a href='https://www.facebook.com/hashtag/emr' target='_blank' className='blog-link'>
                        #EMR </a>
                        <a href='https://www.facebook.com/hashtag/smarterrecords' target='_blank' className='blog-link'>
                        #SmarterRecords </a>
                        <a href='https://www.facebook.com/hashtag/godigital' target='_blank' className='blog-link'>
                        #GoDigital </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog50
