import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b15/img1.jpg';
import img2 from '../../Assets/b15/img2.jpg';

function Blog15(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Cebu Marathon 2023</h1>
            <h6>January 11, 2023 at 11:49 AM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>The #MYTTeam started the year by being fit 🏃‍♀️🏃‍♂️. Our team were among the thousands who passed through the Cebu-Cordova Link Expressway (CCLEX) for the Cebu Marathon 2023. </p>
            <p>The finish line is just the beginning of a whole new race. Congratulations team! 💪</p>
            <p><a href='https://www.facebook.com/hashtag/cebumarathon2023' target='_blank' className='blog-link'>
              #CebuMarathon2023 </a>
              <a href='https://www.facebook.com/hashtag/healthiswealth' target='_blank' className='blog-link'>
              #HealthisWealth </a>
              <a href='https://www.facebook.com/hashtag/fitness' target='_blank' className='blog-link'>
              #Fitness </a>
              <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog15;