import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b8/img1.jpg';
import img2 from '../../Assets/b8/img2.jpg';
import img3 from '../../Assets/b8/img3.jpg';
import img4 from '../../Assets/b8/img4.jpg';

function Blog8(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Welcoming of New Scholars</h1>
            <h6>September 17, 2022 at 05:44 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>We welcome our new high school and college scholars! 👏 Aside 
            from the full scholarship, they also received brand new laptops to 
            help them with their studies.</p>
            <p>In MYT, we #PayItForward. We continuously seek opportunities to 
            help others and this has inspired the team to help driven and 
            passionate students to reach their dreams and become the best person
            they want to be in the future.</p>
            <p>Congratulations Alie May Rose Bilagantol, Anne Eloisa Abelido, 
            and Jermel Lapaz! 🥳</p>
            <p><a href='https://www.facebook.com/hashtag/mytfoundation' target='_blank' className='blog-link'>
            #MYTFoundation</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog8;