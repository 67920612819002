import React from 'react';
import "./Product.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import product1 from '../../Assets/product-dreamtech.png';
import product2 from '../../Assets/product-diagnosys.png';
import product3 from '../../Assets/product-emr.png';
import product4 from '../../Assets/product-hr-connect.png';
import product5 from '../../Assets/product-franchisync.png';
import medease from '../../Assets/medease1.png';

function Product() {
    return (
    <>
        <div className='overflow'>
            <div className='row pb-3'>
                <div className='col text-center products-text'>
                    <h1>Our Products</h1>
                </div>
            </div>
            <div className='overflow'>
                <Row className='row justify-content-center gy-5 mt-4'>
                    <Col>
                        <Row className='about-text text-center product-inline dreamtech'>
                            <Col className='d-flex logo-box mb-2 mb-4'>
                                <img className='product-logo dreamtech-logo mx-auto' src={product1}/>
                            </Col>
                            <Col className='product-dreamtech'>
                                <p>DreamTech is a state-of-the-art hardware and software Point-of-Sale System. 
                                Its user-friendly interface streamlines your business operations, automates inventory management, 
                                nd offers real-time sales reports.Enjoy the peace of mind with top-notch security features, 
                                overnment compliance, and highly-skilled technical support team. DreamTech is designed to help
                                businesses achieve unparalleled success.</p>
                            </Col>
                        </Row>

                        <div className='img-active'>
                            <Row className='about-text text-center product-inline'>
                                <Col className='d-flex col-logo logo-box mb-2'>
                                    <img className='product-logo mx-auto' src={product4}/>
                                </Col>
                                <Col className='product-paragraph right'>
                                    <p>&#x2022; Centralized employee data & digital 201 file</p>
                                    <p>&#x2022; Monitor attendance, leaves, & deductions</p>
                                    <p>&#x2022; Streamlined HR processes</p>
                                </Col>
                            </Row>
                        </div>
                        
                        <div className='img-inactive'>
                            <Row className='about-text text-center product-inline'>
                                <Col className='product-paragraph right'>
                                    <p>&#x2022; Centralized employee data & digital 201 file</p>
                                    <p>&#x2022; Monitor attendance, leaves, & deductions</p>
                                    <p>&#x2022; Streamlined HR processes</p>
                                </Col>
                                <Col className='d-flex col-logo logo-box mb-2'>
                                    <img className='product-logo mx-auto' src={product4}/>
                                </Col>
                            </Row>
                        </div>

                    
                        <Row className='about-text text-center product-inline'>
                            <Col className='d-flex col-logo logo-box mb-2'>
                                <img className='product-logo mx-auto' src={product5}/>
                            </Col>
                            <Col className='product-paragraph right'>
                                <p>&#x2022; Efficiently manage franchise operations</p>
                                <p>&#x2022; Generate payables, receivables and royalties reports</p>
                                <p>&#x2022; Track stock movement from purchases to sales </p>  
                            </Col>
                        </Row>

                        <div className='row pb-3'>
                            <div className='col text-center medease-text'>
                                <img className='product-logo mx-auto' src={medease}/>
                            </div>
                        </div>

                        <div className='container-fluid'>
                            <div className='row justify-content-center'>
                            <div className='col-11 col-md-9 medease2-text text-start text-md-center'>
                                <p>MedEase is an integrated platform for MYT's medical systems,
                                    enabling easy patient data management, communication,
                                    and updated records through EMR+ and DiagnoSys sub-services.</p>
                            </div>
                            </div>
                        </div>

                        <div className='img-active'>
                            <Row className='about-text text-center product-inline'>
                                <Col className='d-flex col-logo logo-box mb-2'>
                                    <img className='product-logo mx-auto' src={product2}/>
                                </Col>
                                <Col className='product-paragraph right'>
                                    <p>&#x2022; End-to-end solution from registration to lab results</p>
                                    <p>&#x2022; Comprehensive laboratory and clinic reports</p>
                                    <p>&#x2022; Easily manage income, expenses, and assets</p>     
                                </Col>
                            </Row>
                        </div>

                        <div className='img-inactive'>
                            <Row className='about-text text-center product-inline'>
                                <Col className='product-paragraph right'>
                                    <p>&#x2022; End-to-end solution from registration to lab results</p>
                                    <p>&#x2022; Comprehensive laboratory and clinic reports</p>
                                    <p>&#x2022; Easily manage income, expenses, and assets</p>
                                </Col>
                                <Col className='d-flex col-logo logo-box mb-2'>
                                    <img className='product-logo mx-auto' src={product2}/>
                                </Col>
                            </Row>
                        </div>

                        <Row className='about-text text-center product-inline'>
                            <Col className='d-flex col-logo logo-box mb-2'>
                                <img className='product-logo mx-auto' src={product3}/>
                            </Col>
                            <Col className='product-paragraph right'>
                                <p>&#x2022; Comprehensive consultation recording</p>
                                <p>&#x2022; Smart digital prescription</p>
                                <p>&#x2022; Robust patient record management</p>
                            </Col>
                        </Row>
                    </Col>   
                </Row>
            </div>
        </div>
    </>
    );
  }
  
  export default Product;