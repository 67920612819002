import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b11/img1.jpg';
import img2 from '../../Assets/b11/img2.jpg';
import img3 from '../../Assets/b11/img3.jpg';
import img4 from '../../Assets/b11/img4.jpg';

function Blog11(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>Financial Talk: Retirement Plan</h1>
            <h6>November 09, 2022 at 11:51 AM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img4} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>When we reach retirement age, we enter a new and exciting phase
              of our lives. It's the time to create more memories and pursue the
              goals we might have missed from our younger years.</p>
            <p>Here in MYT, we achieve these goals by having a game plan to 
              prepare ourselves for the challenges ahead. We make our employees' 
              vision into reality and help build their retirement fund to have 
              a comfortable, financially secure life in the future.</p>
            <p>In partnership with AXA Philippines, we help our employees to be 
              financially empowered by providing all regular employees a 
              retirement plan. We'd like to extend our gratitude to&nbsp; 
              <a href='https://www.facebook.com/mickiego' target='_blank' className='blog-link'>
              Miss Mickie Go - Mella</a>&nbsp;
              from AXA for hosting the Financial Talk on Retirement Plan.</p>
            <p><a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='blog-link'>
              #MYTCulture </a>
              <a href='https://www.facebook.com/hashtag/financialtalk' target='_blank' className='blog-link'>
              #FinancialTalk </a>
              <a href='https://www.facebook.com/hashtag/retirementplan' target='_blank' className='blog-link'>
              #RetirementPlan</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog11;