import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b29/eleventhanniv1.jpg';
import img2 from '../../Assets/b29/eleventhanniv2.jpg';
import img3 from '../../Assets/b29/eleventhanniv3.jpg';

function Blog29(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>MYT: A decade plus one of evolution and growth!</h1>
            <h6>July 3, 2023 at 7:09 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Celebrating 11 years of evolution and growth! 🌟🎉</p>
             <p>Join us as we mark another milestone at MYT SoftDev Solutions, Inc. For the past 11 years, we've been on an incredible journey of growth and transformation, constantly adapting and evolving in the fast-paced world of technology. 🌐💡</p>
             <p>We owe our success to the unwavering commitment and dedication of our exceptional team, as well as the continued trust and support of our valued clients. Together, we have overcome challenges, explored new possibilities, and created innovative solutions. 🚀✨</p>
             <p>Here's to 11 years, and to an exciting future filled with limitless possibilities! 🎉</p>
             <p><a href='https://www.facebook.com/hashtag/mytislit' target='_blank' className='blog-link'>
              #MYTisLit </a>
              <a href='https://www.facebook.com/hashtag/myt11years' target='_blank' className='blog-link'>
              #MYT11Years </a>
              <a href='https://www.facebook.com/hashtag/mytevolve' target='_blank' className='blog-link'>
              #MYTEvolve </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog29;