import React from 'react';
import "./Services.css"
import service1 from '../../Assets/service1.png';
import service2 from '../../Assets/service2.png';
import service3 from '../../Assets/service3.png';
import service4 from '../../Assets/service4.png';
import service5 from '../../Assets/service5.png';
import stack1 from '../../Assets/stack1.png';
import stack2 from '../../Assets/stack2.png';
import stack3 from '../../Assets/stack3.png';
import stack4 from '../../Assets/stack4.png';
import stack5 from '../../Assets/stack5.png';

function Services() {
  return (
    <>
      <div className='container mt-5 mb-5 pb-5'>
        <div className='row pb-3'>
          <div className='col text-center services-text'>
            <h1>Our Services</h1>
          </div>
        </div>

        <div className='row card-text gy-5 pb-5 justify-content-center'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='card mx-auto text-center' style={{backgroundColor:'#ee4035'}}>
              <img className='mb-3' src={service1}/>
              <h1>Enterprise Application Solution</h1>
              <p className='ps-2 pe-2'>Fully-customizable software solution for your business' needs</p>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='card mx-auto text-center' style={{backgroundColor:'#f37736'}}>
              <img className='mb-3' src={service2}/>
              <h1>Mobile App Development</h1>
              <p className='ps-2 pe-2'>User-friendly and optimized native & cross-platform applications</p>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='card mx-auto text-center' style={{backgroundColor:'#f1cb52'}}>
              <img className='mb-3' src={service3}/>
              <h1>Web Design and Development</h1>
              <p className='ps-2 pe-2' style={{color:'white'}}>Well-designed and reflective of your company's image</p>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='card mx-auto text-center' style={{backgroundColor:'#77b36e'}}>
              <img className='mb-3' src={service4}/>
              <h1>Custom Gamification Solution</h1>
              <p className='ps-2 pe-2'>Incorporate game design elements to improve user engagement</p>
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='card-no-padding mx-auto text-center' style={{backgroundColor:'#0592cf'}}>
              <img className='mb-3' id='img-extend' src={service5}/>
              <h1>Digital Graphics<br></br>Design and Animation</h1>
              <p className='ps-2 pe-2'>High-quality digital illustrations and animations</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container mt-5 mb-5 pb-5'>
        <div className='row pb-3'>
          <div className='col text-center services-text'>
            <h1>Our Tech Stack</h1>
            <p>We develop custom software application for: Windows, Linux/Unix, Mac OS, 
            iOS, Android OS.</p>
          </div>
        </div>

        <div className='row gy-5 justify-content-center'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center'>
            <img className='card-image' src={stack1}/>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center'>
            <img className='card-image' src={stack2}/>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center'>
            <img className='card-image' src={stack3}/>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center'>
            <img className='card-image' src={stack4}/>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 text-center'>
            <img className='card-image' src={stack5}/>
          </div>
        </div>        
      </div>
    </>
  );
}

export default Services;