import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b9/img1.jpg';
import img2 from '../../Assets/b9/img2.jpg';
import img3 from '../../Assets/b9/img3.jpg';

function Blog9(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>MYT Opens its New Office</h1>
            <h6>October 04, 2022 at 09:21 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
            <div id="blogImages" className="carousel slide mx-auto" data-bs-ride="true" data-bs-interval='3000'>
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#blogImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img1} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img2} className="blog-img" alt="..."/>
                </div>
                <div className="carousel-item">
                  <img src={img3} className="blog-img" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>Our new office in our old office. 💙</p>
            <p>Ten bulbs to celebrate 10 years - these bulbs perfectly represent 
            what happens daily in the office - nurturing ideas, creativity, and 
            a continuous thirst for knowledge. 💡</p>
            <p>And meet the Decision Tree! In our line of work, the decision tree 
            analysis is a crucial step to be able to offer the best possible 
            solution we can provide to solve our customers’ needs. 🌳</p>
            <p>At <a href='https://www.facebook.com/hashtag/myt' target='_blank' className='blog-link'>
              #MYT</a>, we value positive work environment and it starts with a
             fun atmosphere! 🎉</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog9;