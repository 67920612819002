import React from 'react';
import "./BlogPost.css";
import img1 from '../../Assets/b4/img1.jpg';

function Blog4(){
  return (
    <>
      <div className='container-fluid my-5'>
        <div className='row mb-5'>
          <div className='col blog-text text-center'>
            <h1>MYT is now NPC-Certified!</h1>
            <h6>August 04, 2022 at 04:05 PM</h6>
          </div>
        </div>

        <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 text-center'>
            <img src={img1} className="blog-img"/>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
            <p>We are now certified with the National Privacy Commission.</p>
            <p>We do not only provide solutions but we also value protecting your
            data. We comply with the international standards set for data
            protection through the NPC.</p>
            <p><a href='https://www.facebook.com/hashtag/dataprivacyact' target='_blank' className='blog-link'>
              #DataPrivacyAct </a>
              <a href='https://www.facebook.com/hashtag/dataprotection' target='_blank' className='blog-link'>
              #DataProtection </a>
              <a href='https://www.facebook.com/hashtag/nationalprivacycommission' target='_blank' className='blog-link'>
              #NationalPrivacyCommission</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog4;