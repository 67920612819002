import React from 'react'
import "./BlogPost.css";

import heroshot from "../../Assets/b46/heroshot.jpg"
import img1 from "../../Assets/b46/img1.jpg"

function Blog46() {
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col blog-text text-center'>
                    <h1>Optimizing Code with SonarQube</h1>
                    <h6>June 5, 2024 at 2:57 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="blogImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#blogImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={heroshot} className="blog-img" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={img1} className="blog-img" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#blogImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#blogImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 blog-text'>
                    <p>Another successful workshop in the books! A big thank you to our QA Specialist, Clyde, for leading the workshop about SonarQube 👏. In the workshop, Clyde emphasized the crucial responsibility developers have in ensuring that their code is clean, efficient, and maintainable. 💻✨</p>
                    <p>What’s SonarQube?</p>
                    <p>Think of it as your code’s personal fitness trainer. It analyzes your code, finds bugs, and security vulnerabilities, and helps you write cleaner, safer, and more efficient code. 🚀</p>
                    <p>Clean code isn't just a best practice — it's the foundation for successful and sustainable projects. Let’s commit to upholding the highest standards in our coding practices!</p>
                    <p><a href='https://www.facebook.com/hashtag/cleancode' target='_blank' className='blog-link'>
                        #CleanCode </a>
                        <a href='https://www.facebook.com/hashtag/codequality' target='_blank' className='blog-link'>
                        #CodeQuality </a>
                        <a href='https://www.facebook.com/hashtag/sonarqube' target='_blank' className='blog-link'>
                        #SonarQube </a>
                        <a href='https://www.facebook.com/hashtag/continuousimprovement' target='_blank' className='blog-link'>
                        #ContinuousImprovement </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Blog46
